import React, { useState, useEffect } from "react";
import InputSearch from "../../../Components/Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../Components/Dashboard/Buttons/ButtonPrimary";
import DataTable, { createTheme } from "react-data-table-component";
import StatusModal from "../Modales/StatusModal";
import DeleteModal from "../Modales/DeleteModal";
import { updateOrderStatus } from "../../../Services/orderService";
import { useNavigate } from "react-router";
import authServiceInstance from "../../../Services/authService";
import { getDate, getFormatTime } from "../../../utils/dates";
import { LoadingData } from "../../../Components/loaders/LoadingData";

const List = ({
  changeStatus,
  listOrders,
  fetchOrders,
  isLoading,
  onPageChange,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  searchTerm,
  setSearchTerm
}) => {
  const [orders, setOrders] = useState([]);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idOrder, setIdOrder] = useState(0);
  const [ordersAux, setOrdersAux] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterAll, setFilterAll] = useState(true);
  const [filterActive, setFilterActive] = useState(false);
  const [filterDeleted, setFilterDeleted] = useState(false);
  const [statusData, setStatusData] = useState({ id_status: 0, note: "" });
  const token = authServiceInstance.getSessionToken();
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setOrders(listOrders?.data);
    setOrdersAux(listOrders?.data);
    setTotalCount(listOrders?.info?.totalRows);
    setActiveCount(listOrders?.data?.filter((row) => row.id_status !== 39).length);
    setDeletedCount(listOrders?.data?.filter((row) => row.id_status === 39).length);
  }, [listOrders]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setOrdersAux(orders?.slice(start, end));
  }, [currentPage, pageSize, orders]);

  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    /*const newData = orders.filter((row) => {
      return (
        row.client_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.order_number.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setOrdersAux(newData);*/
    setSearchTerm(event.target.value)
  
  };

  const handleCheckboxChange = (filterType) => {
    setFilterAll(filterType === "all");
    setFilterActive(filterType === "active");
    setFilterDeleted(filterType === "deleted");
  };

  const applyFilters = () => {
    if (filterAll) {
      setOrdersAux(orders);
    } else if (filterActive) {
      const activeOrders = orders.filter((row) => row.id_status !== 39 && row.id_status !== 16);
      setOrdersAux(activeOrders);
    } else if (filterDeleted) {
      const deletedOrders = orders.filter((row) => row.id_status === 39);
      setOrdersAux(deletedOrders);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterAll, filterActive, filterDeleted, orders]);

  const handleDelete = async () => {
    try {
      await updateOrderStatus(idOrder, statusData, token);
      setIdOrder(0);
      setStatusData({ id_status: 0, note: "" });
      setOpenModalDelete(false);
      fetchOrders();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  const handleStatus = async (isCheckedSend) => {
    if (statusData.id_status !== 0) {
      const newData = {
        sendEmail: isCheckedSend,
        ...statusData,
      };
      try {
        await updateOrderStatus(idOrder, newData, token);
        setIdOrder(0);
        setStatusData({ id_status: 0, note: "" });
        setOpenModalStatus(false);
        fetchOrders();
      } catch (error) {
        console.error("Error deleting order:", error.message);
      }
    } else {
      setIdOrder(0);
      setStatusData({ id_status: 0, note: "" });
      setOpenModalStatus(false);
    }
  };
  

  const customCell = (row) => (
    <div className="dropdown drop-menu">
      <button
        className="btn btn-secondary dropdown-toggle action-menu"
        type="button"
        id={`dropdownMenuButton${row.id}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </button>
      <ul className="dropdown-menu list-menu p-0" aria-labelledby={`dropdownMenuButton${row.id}`}>
        <li>
          <a className="dropdown-item" href="#" onClick={() => handleMenuClick(row, "edit")}>
            Ver orden
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#" onClick={() => handleMenuClick(row, "status")}>
            Cambiar estado
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#" onClick={() => handleMenuClick(row, "delete")}>
            Eliminar orden
          </a>
        </li>
      </ul>
    </div>
  );

  const columns = [
    { name: "Nro de Orden", selector: (row) => "#" + row.order_number, sortable: true },
    {
      name: "Fecha de Creación",
      sortable: true,
      selector: (row) => (
        <div className="d-flex flex-column">
          <span>{getDate(row.created_at)}</span>
          <small>{getFormatTime(row.created_at)}</small>
        </div>
      ),
    },
    {
      name: "Creado por",
      selector: (row) =>
        ` ${
          row.user_creator?.name ? row.user_creator?.name + " " + row.user_creator?.last_name : "Sin Datos"
        }`,
      sortable: true,
    },
    { name: "Cliente", selector: (row) => row.client_name, sortable: true },
    { name: "Servicio", selector: (row) => row.item, sortable: true },
    {
      name: "Estado",
      selector: (row) => row.id_status,
      sortable: true,
      cell: (row) => (
        <div className="px-3">
          {row.id_status === 39 ? (
            <span className="status-delete">Eliminado</span>
          ) : row.id_status === 16 ? (
            <span className="text-ellipsis inline-block max-w-[220px] border-cancel bg-[#F4FFFF] overflow-hidden whitespace-nowrap text-sm text-textError py-1.5 px-3 rounded-[10px]">
              Cerrado
            </span>
          ) : (
            <span className="status-active">{row.status?.status_name || "Sin estado"}</span>
          )}
        </div>
      ),
    },
    { name: "", cell: customCell },
  ];

  const handleMenuClick = (order, type) => {
    if (type === "status") {
      setOpenModalStatus(true);
      setIdOrder(order.id_service_order);
      setStatusData({
        id_status: order.id_status,
        note: "",
      });
    } else if (type === "delete") {
      setStatusData({
        id_status: 39,
        note: "",
      });
      setOpenModalDelete(true);
      setIdOrder(order.id_service_order);
    } else if (type === "edit") {
      navigate(`/admin-itc/order/${order.id_service_order}`, {
        state: { data: order },
      });
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#6B6B6B",
        fontWeight: 600,
        fontFamily: "Manrope",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "#001222",
        fontWeight: 500,
        fontFamily: "Manrope",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <>
      <StatusModal
        openModalStatus={openModalStatus}
        setOpenModalStatus={setOpenModalStatus}
        action={handleStatus}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <DeleteModal
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        action={handleDelete}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <div className="row">
        <div className="col-12 col-sm-8 col-md-10 mt-1">
          <InputSearch
            value={searchValue}
            setValue={handleFilter}
            filterLabel="por Nro de Orden o Cliente"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-2 mt-1" style={{ minHeight: "35px" }}>
          <ButtonPrimary change={changeStatus} text={"Añadir Orden"} />
        </div>
        <div className="col-md-12 mt-4">
        <DataTable
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          paginationComponentOptions={{ rowsPerPageText: "Filas por página" }}
          onChangePage={onPageChange}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(currentPage);
          }}
          columns={columns}
          progressPending={isLoading}
          noDataComponent={"No hay registros para mostrar"}
          data={ordersAux}
          customStyles={customStyles}
          progressComponent={
            <div className="my-6 mx-auto">
              <p className="mb-3">Cargando Datos...</p>
              <LoadingData />
            </div>
          }
        />
        </div>
      </div>
    </>
  );
};

export default List;
